<template>
  <div class="relative w-5/6 md:w-2/3 lg:w-7/12 mx-auto">
    <div class="relative w-full rounded-3xl p-8 bg-white shadow-md">
      <div class="flex flex-col justify-center">
        <h1
          class="text-2xl text-center md:text-left md:text-5xl text-primary-1000"
        >
          {{ $i18n.t("login") }}
        </h1>
        <div class="flex items-center mt-2">
          <p class="font-light mr-2">{{ $i18n.t("dont_have_an_account") }}</p>
          <t-button variant="link" to="/signup" :disabled="isLoading">{{
            $i18n.t("signup")
          }}</t-button>
        </div>

        <div class="mt-8">
          <t-input-group
            :feedback="
              showValidation && errors.first('email')
                ? errors.first('email')
                : ''
            "
            :variant="showValidation && errors.first('email') ? 'danger' : ''"
            class="mb-2"
          >
            <t-input
              v-validate="'required'"
              data-vv-validate-on="blur"
              classes="mb-4"
              v-model="user.email"
              name="email"
              :placeholder="$i18n.t('email_or_username')"
              autocomplete="off"
              :disabled="isLoading"
              :variant="showValidation && errors.first('email') ? 'danger' : ''"
            />
          </t-input-group>

          <t-input-group
            :feedback="
              showValidation && errors.first('password')
                ? errors.first('password')
                : ''
            "
            :variant="
              showValidation && errors.first('password') ? 'danger' : ''
            "
            class="mb-2"
          >
            <t-input
              v-on:keyup.enter="login"
              v-validate="'required'"
              data-vv-validate-on="blur"
              classes="mb-4"
              v-model="user.password"
              name="password"
              :placeholder="$i18n.t('password')"
              autocomplete="off"
              type="password"
              ref="password"
              :disabled="isLoading"
              :variant="
                showValidation && errors.first('password') ? 'danger' : ''
              "
            />
          </t-input-group>

          <t-button
            class="ml-auto"
            variant="link"
            to="/reset_password"
            :disabled="isLoading"
            >{{ $i18n.t("forgot_password") }}</t-button
          >
          <t-button class="mt-3 w-full" @click="login" :disabled="isLoading">
            {{ $i18n.t("login") }}
            <t-loader
              v-if="isLoading"
              extraClass="ml-2 border-orange-primary"
            />
          </t-button>

          <div class="mt-3 px-auto text-center">
            <div stlye="max-width: 100%;" id="buttonDiv"></div> 
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getAuth, signInWithCredential, GoogleAuthProvider } from "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyCx9-bQ1mySk5KlpXlj1DG52GCQgspU4SE",
  authDomain: "jevi-app-351913.firebaseapp.com",
  projectId: "jevi-app-351913",
  storageBucket: "jevi-app-351913.appspot.com",
  messagingSenderId: "695299874820",
  appId: "1:695299874820:web:fc8abe52bd1935a7b75551",
  measurementId: "G-CPZ1J0C1EQ"
}

initializeApp(firebaseConfig)

const notify = () => import("@/utils/notify.js")

export default {
  data() {
    return {
      client: null,
      user: {
        email: "",
        password: ""
      },
      showValidation: false,
      isLoading: false,
    }
  },

  mounted() {
    this.client = google.accounts.oauth2.initTokenClient({
      client_id: '695299874820-c49ksalmt2o26is2gre8mvut5lv3mbbo.apps.googleusercontent.com',
      scope: 'email https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/youtube.force-ssl',
      ux_mode: 'prompt',
      callback: this.handleTokenResponse
    })

    google.accounts.id.initialize({
      client_id: "695299874820-c49ksalmt2o26is2gre8mvut5lv3mbbo.apps.googleusercontent.com",
      callback: this.handleCredentialResponse
    })

    google.accounts.id.renderButton(
      document.getElementById("buttonDiv"),
      { theme: "outline", size: "large", logo_alignment: "center", width: '100%', locale: this.$i18n.locale + (this.$i18n.locale == 'es' ? '_ES' : '_US') }
    )

    google.accounts.id.prompt()
  },

  methods: {
    async handleCredentialResponse(response) {
      //this.client.requestCode()
      this.client.requestAccessToken()

      function parseJwt (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
      }

      const payload = parseJwt(response.credential)

      // Build Firebase credential with the Google ID token.
      const idToken = response.credential;
      const credential = GoogleAuthProvider.credential(idToken);

      const auth = getAuth()
      
      // Sign in with credential from the Google user.
      signInWithCredential(auth, credential)
        .then((response) => {
          payload.accessToken = response.user.accessToken

          this.isLoading = true

          this.$store
            .dispatch("auth/googleLogin", {
              payload,
              aclChangeRole: this.$acl.change,
              i18n: this.$i18n
            })
            .then((response) => {
              this.isLoading = false

              if (response.code === 200 && response.data.active == 2) {
                notify().then(({ notification }) => {
                  notification(
                    "must-register",
                    response.code,
                    this.$snotify,
                    this.$i18n.locale
                  )
                })
              }
              else {
                notify().then(({ notification }) => {
                  notification(
                    "login",
                    response.code,
                    this.$snotify,
                    this.$i18n.locale
                  )
                })
                
                this.$socket.connect()
                
                this.$socket.emit("user_connected", {
                  id_user: response.data.id
                })
                this.$socket.emit("join_topics", {
                  id_user: response.data.id
                })
                this.$socket.emit("join_room", {
                  id_user: response.data.id
                })
              }
            })
            .catch((error) => {
              this.isLoading = false

              notify().then(({ notification }) => {
                notification(
                  "error",
                  error.code,
                  this.$snotify,
                  this.$i18n.locale
                )
              })
            })
        })
        .catch((error) => {
          console.log(error)
        });
    },

    async handleTokenResponse(tokenResponse) {
      console.log(tokenResponse)

      if (tokenResponse && tokenResponse.access_token) {
        if (google.accounts.oauth2.hasGrantedAllScopes(tokenResponse,
          'https://www.googleapis.com/auth/youtube.force-ssl',
          'https://www.googleapis.com/auth/youtube')) {
          //Accepted likes, comments and subscriptions
          console.log("Accepted all scopes")
          this.$store.dispatch("auth/setOAuthPreferences", {
            accessToken: tokenResponse.access_token,
            likes_comments: true,
            subscriptions: true
          })

          return
        }

        if (google.accounts.oauth2.hasGrantedAnyScopes(tokenResponse,
          'https://www.googleapis.com/auth/youtube')) {
          //Accepts likes and comments
          console.log("Accepted likes and comments scope")
          this.$store.dispatch("auth/setOAuthPreferences", {
            accessToken: tokenResponse.access_token,
            likes_comments: true,
            subscriptions: false
          })

          return
        }

        if (google.accounts.oauth2.hasGrantedAnyScopes(tokenResponse,
          'https://www.googleapis.com/auth/youtube.force-ssl')) {
          //Acepts subscriptions
          console.log("Accepted subscriptions scope")
          this.$store.dispatch("auth/setOAuthPreferences", {
            accessToken: tokenResponse.access_token,
            likes_comments: false,
            subscriptions: true
          })

          return
        }
      }
    },

    login() {
      this.$validator.validateAll().then((isValid) => {
        this.showValidation = true
        if (isValid) {
          //this.client.requestCode()
          //this.client.requestAccessToken()
          this.isLoading = true
          this.$store
            .dispatch("auth/login", {
              user: this.user,
              aclChangeRole: this.$acl.change,
              i18n: this.$i18n
            })
            .then((response) => {
              this.isLoading = false
              notify().then(({ notification }) => {
                notification(
                  "login",
                  response.code,
                  this.$snotify,
                  this.$i18n.locale
                )
              })
              if (response.code === 200) {
                this.$socket.connect()
                this.$socket.emit("user_connected", {
                  id_user: response.data.id
                })
                this.$socket.emit("join_topics", {
                  id_user: response.data.id
                })
                this.$socket.emit("join_room", {
                  id_user: response.data.id
                })
              }
            })
            .catch((error) => {
              this.isLoading = false
              notify().then(({ notification }) => {
                notification(
                  "error",
                  error.code,
                  this.$snotify,
                  this.$i18n.locale
                )
              })
            })
        }
      })
    },
  },
}
</script>
