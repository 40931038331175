var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative w-5/6 md:w-2/3 lg:w-7/12 mx-auto"},[_c('div',{staticClass:"relative w-full rounded-3xl p-8 bg-white shadow-md"},[_c('h1',{staticClass:"text-2xl text-center md:text-left md:text-5xl text-primary-1000"},[_vm._v(" "+_vm._s(_vm.$i18n.t("reset_password"))+" ")]),_c('div',{staticClass:"flex flex-col justify-center"},[(_vm.token)?[_c('p',{staticClass:"font-light text-center mt-6"},[_vm._v(" "+_vm._s(_vm.$i18n.t("enter_new_password"))+" ")]),_c('div',{staticClass:"mt-8"},[_c('t-input-group',{staticClass:"mb-2 relative",attrs:{"feedback":_vm.showValidation && _vm.errors.first('password')
                ? _vm.errors.first('password')
                : '',"variant":_vm.showValidation && _vm.errors.first('password') ? 'danger' : ''}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:6'),expression:"'required|min:6'"}],ref:"password",attrs:{"data-vv-validate-on":"blur","classes":"mb-4 bg-transparent","name":"password","placeholder":_vm.$i18n.t('password'),"autocomplete":"off","disabled":_vm.isLoading,"type":_vm.type,"variant":_vm.showValidation && _vm.errors.first('password') ? 'danger' : ''},model:{value:(_vm.recovery.password),callback:function ($$v) {_vm.$set(_vm.recovery, "password", $$v)},expression:"recovery.password"}}),_c('span',{staticClass:"absolute top-20 right-3 cursor-pointer",on:{"click":function($event){return _vm.showPassword('typeInput')}}},[_c('icon',{attrs:{"icon":_vm.typeInput === 'password' ? 'eye' : 'eye-slash'}})],1)],1),_c('t-input-group',{staticClass:"mb-2 relative",attrs:{"feedback":_vm.showValidation && _vm.errors.first('passwordConfirm')
                ? _vm.errors.first('passwordConfirm')
                : '',"variant":_vm.showValidation && _vm.errors.first('passwordConfirm')
                ? 'danger'
                : ''}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|confirmed:password|min:6'),expression:"'required|confirmed:password|min:6'"}],attrs:{"data-vv-validate-on":"blur","classes":"mb-4 bg-transparent","name":"passwordConfirm","placeholder":_vm.$i18n.t('confirm_password'),"autocomplete":"off","disabled":_vm.isLoading,"type":_vm.typeConfirm,"variant":_vm.showValidation && _vm.errors.first('passwordConfirm')
                  ? 'danger'
                  : ''},model:{value:(_vm.recovery.passwordConfirm),callback:function ($$v) {_vm.$set(_vm.recovery, "passwordConfirm", $$v)},expression:"recovery.passwordConfirm"}}),_c('span',{staticClass:"absolute top-20 right-3 cursor-pointer",on:{"click":function($event){return _vm.showPassword('typeInputConfirm')}}},[_c('icon',{attrs:{"icon":_vm.typeInputConfirm === 'password' ? 'eye' : 'eye-slash'}})],1)],1),_c('t-button',{staticClass:"mt-3 w-full",attrs:{"disabled":_vm.isLoading},on:{"click":_vm.changePassword}},[_vm._v(" "+_vm._s(_vm.$i18n.t("update_password"))+" "),(_vm.isLoading)?_c('t-loader',{attrs:{"extraClass":"ml-2 border-orange-primary"}}):_vm._e()],1)],1)]:[_c('div',{staticClass:"flex items-center mt-2"},[_c('p',{staticClass:"font-light"},[_vm._v(" "+_vm._s(_vm.$i18n.t("enter_your_signup_email"))+" "),_c('t-button',{staticClass:"p-0",attrs:{"variant":"link","to":"/login","disabled":_vm.isLoading}},[_vm._v(_vm._s(_vm.$i18n.t("login")))])],1)]),_c('div',{staticClass:"mt-8"},[_c('t-input-group',{staticClass:"mb-2",attrs:{"feedback":_vm.showValidation && _vm.errors.first('email')
                ? _vm.errors.first('email')
                : '',"variant":_vm.showValidation && _vm.errors.first('email') ? 'danger' : ''}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|email'),expression:"'required|email'"}],attrs:{"data-vv-validate-on":"blur","classes":"mb-4","name":"email","placeholder":_vm.$i18n.t('email'),"autocomplete":"off","disabled":_vm.isLoading,"type":"email","variant":_vm.showValidation && _vm.errors.first('email') ? 'danger' : ''},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1),_c('t-button',{staticClass:"mt-3 w-full",attrs:{"disabled":_vm.isLoading},on:{"click":_vm.send}},[_vm._v(_vm._s(_vm.$i18n.t("send_instructions_to_mail"))+" "),(_vm.isLoading)?_c('t-loader',{attrs:{"extraClass":"ml-2 border-orange-primary"}}):_vm._e()],1)],1)]],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }