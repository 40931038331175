var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-screen mt-3 md:flex md:items-center"},[_c('div',{staticClass:"relative w-5/6 md:w-2/3 mx-auto"},[_c('div',{staticClass:"relative w-full rounded-3xl p-8 bg-white shadow-md"},[_c('h1',{staticClass:"text-2xl text-center md:text-left md:text-5xl text-primary-1000"},[_vm._v(" "+_vm._s(_vm.$i18n.t("signup"))+" ")]),_c('div',{staticClass:"flex flex-col justify-center"},[_c('div',{staticClass:"flex items-center mt-2"},[_c('p',{staticClass:"font-light mr-2"},[_vm._v(" "+_vm._s(_vm.$i18n.t("already_have_an_account"))+" ")]),_c('t-button',{attrs:{"variant":"link","to":"/login","disabled":_vm.isLoading}},[_vm._v(_vm._s(_vm.$i18n.t("login")))])],1),_c('div',{staticClass:"mt-8"},[_c('div',{staticClass:"flex flex-col md:flex-row"},[_c('t-input-group',{staticClass:"md:w-1/2 md:mr-2 mb-2",attrs:{"feedback":_vm.showValidation && _vm.errors.first('first_name')
                  ? _vm.errors.first('first_name')
                  : '',"variant":_vm.showValidation && _vm.errors.first('first_name') ? 'danger' : ''}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:3|max:40'),expression:"'required|min:3|max:40'"}],attrs:{"data-vv-validate-on":"blur","classes":"mb-4 bg-transparent","name":"first_name","placeholder":_vm.$i18n.t('name'),"autocomplete":"off","disabled":_vm.isLoading,"type":"text","variant":_vm.showValidation && _vm.errors.first('first_name') ? 'danger' : ''},model:{value:(_vm.user.first_name),callback:function ($$v) {_vm.$set(_vm.user, "first_name", $$v)},expression:"user.first_name"}})],1),_c('t-input-group',{staticClass:"md:w-1/2 md:mr-2 mb-2",attrs:{"feedback":_vm.showValidation && _vm.errors.first('last_name')
                  ? _vm.errors.first('last_name')
                  : '',"variant":_vm.showValidation && _vm.errors.first('last_name') ? 'danger' : ''}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:3|max:40'),expression:"'required|min:3|max:40'"}],attrs:{"data-vv-validate-on":"blur","classes":"mb-4 bg-transparent","name":"last_name","placeholder":_vm.$i18n.t('last_name'),"autocomplete":"off","disabled":_vm.isLoading,"type":"text","variant":_vm.showValidation && _vm.errors.first('last_name') ? 'danger' : ''},model:{value:(_vm.user.last_name),callback:function ($$v) {_vm.$set(_vm.user, "last_name", $$v)},expression:"user.last_name"}})],1)],1),_c('div',{staticClass:"flex mb-2 w-full"},[_c('t-input-group',{class:_vm.checkingUsername ? 'w-11/12' : 'w-full',attrs:{"feedback":_vm.showValidation && _vm.errors.first('username')
                  ? _vm.errors.first('username')
                  : '',"variant":_vm.showValidation && _vm.errors.first('username') ? 'danger' : ''}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|username'),expression:"'required|username'"}],attrs:{"data-vv-validate-on":"input","classes":"mb-4 bg-transparent","name":"username","placeholder":_vm.$i18n.t('username'),"autocomplete":"off","disabled":_vm.isLoading,"variant":_vm.showValidation && _vm.errors.first('username') ? 'danger' : ''},on:{"blur":_vm.debouncedInput},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}})],1),(_vm.checkingUsername)?_c('icon',{staticClass:"text-xl mt-3 mx-auto",class:_vm.validUsername ? 'text-green-600' : 'text-red-600',attrs:{"icon":_vm.validUsername ? 'check-circle' : 'times-circle'}}):_vm._e()],1),_c('t-input-group',{staticClass:"mb-2",attrs:{"feedback":_vm.showValidation && _vm.errors.first('email')
                ? _vm.errors.first('email')
                : '',"variant":_vm.showValidation && _vm.errors.first('email') ? 'danger' : ''}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|email'),expression:"'required|email'"}],attrs:{"data-vv-validate-on":"blur","classes":"mb-4 bg-transparent","name":"email","placeholder":_vm.$i18n.t('email'),"autocomplete":"off","disabled":_vm.isLoading || (_vm.googleUser && _vm.googleUser.email.length > 0),"type":"email","variant":_vm.showValidation && _vm.errors.first('email') ? 'danger' : ''},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1),_c('t-input-group',{staticClass:"mb-2",attrs:{"feedback":_vm.showValidation && _vm.errors.first('lang')
                ? _vm.errors.first('lang')
                : '',"variant":_vm.showValidation && _vm.errors.first('lang') ? 'danger' : ''}},[_c('t-rich-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-validate-on":"blur","name":"lang","placeholder":_vm.$i18n.t('language'),"disabled":_vm.isLoading,"noResultsText":"No results","variant":_vm.showValidation && _vm.errors.first('lang') ? 'danger' : '',"options":_vm.languages,"textAttribute":'text_' + _vm.$i18n.locale},model:{value:(_vm.user.lang),callback:function ($$v) {_vm.$set(_vm.user, "lang", $$v)},expression:"user.lang"}})],1),_c('t-input-group',{staticClass:"mb-2",attrs:{"feedback":_vm.showValidation && _vm.errors.first('id_country')
                ? _vm.errors.first('id_country')
                : '',"variant":_vm.showValidation && _vm.errors.first('id_country') ? 'danger' : ''}},[_c('t-rich-select',{attrs:{"name":"id_country","placeholder":_vm.$i18n.t('country') + ' ' + _vm.$i18n.t('optional'),"disabled":_vm.isLoading,"noResultsText":"No results","variant":_vm.showValidation && _vm.errors.first('id_country') ? 'danger' : '',"options":_vm.countries,"textAttribute":_vm.$i18n.locale + '_name'},model:{value:(_vm.user.id_country),callback:function ($$v) {_vm.$set(_vm.user, "id_country", $$v)},expression:"user.id_country"}})],1),_c('t-input-group',{staticClass:"mb-2 relative",attrs:{"feedback":_vm.showValidation && _vm.errors.first('password')
                ? _vm.errors.first('password')
                : '',"variant":_vm.showValidation && _vm.errors.first('password') ? 'danger' : ''}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],ref:"password",attrs:{"data-vv-validate-on":"blur","classes":"mb-4 bg-transparent","name":"password","placeholder":_vm.$i18n.t('password'),"autocomplete":"off","disabled":_vm.isLoading,"type":_vm.type,"variant":_vm.showValidation && _vm.errors.first('password') ? 'danger' : ''},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('span',{staticClass:"absolute top-20 right-3 cursor-pointer",on:{"click":function($event){return _vm.showPassword('typeInput')}}},[_c('icon',{attrs:{"icon":_vm.typeInput === 'password' ? 'eye' : 'eye-slash'}})],1)],1),_c('t-input-group',{staticClass:"mb-2 relative",attrs:{"feedback":_vm.showValidation && _vm.errors.first('passwordConfirm')
                ? _vm.errors.first('passwordConfirm')
                : '',"variant":_vm.showValidation && _vm.errors.first('passwordConfirm')
                ? 'danger'
                : ''}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|confirmed:password'),expression:"'required|confirmed:password'"}],attrs:{"data-vv-validate-on":"blur","classes":"mb-4 bg-transparent","name":"passwordConfirm","placeholder":_vm.$i18n.t('confirm_password'),"autocomplete":"off","disabled":_vm.isLoading,"type":_vm.typeConfirm,"variant":_vm.showValidation && _vm.errors.first('passwordConfirm')
                  ? 'danger'
                  : ''},model:{value:(_vm.passwordConfirm),callback:function ($$v) {_vm.passwordConfirm=$$v},expression:"passwordConfirm"}}),_c('span',{staticClass:"absolute top-20 right-3 cursor-pointer",on:{"click":function($event){return _vm.showPassword('typeInputConfirm')}}},[_c('icon',{attrs:{"icon":_vm.typeInputConfirm === 'password' ? 'eye' : 'eye-slash'}})],1)],1),_c('div',{staticClass:"flex items-center"},[_c('t-checkbox',{model:{value:(_vm.acceptedTerms),callback:function ($$v) {_vm.acceptedTerms=$$v},expression:"acceptedTerms"}}),_c('span',{staticClass:"ml-2 text-sm"},[_vm._v(_vm._s(_vm.$i18n.t("read_and_accepted")))]),_c('a',{staticClass:"ml-1 my-auto",staticStyle:{"color":"#555bcd"},attrs:{"href":"/termsandconditions_es.pdf","target":"_blank"}},[_c('strong',[_vm._v(_vm._s(_vm.$i18n.t("the_terms_and_conditions")))])])],1),_c('div',{staticClass:"flex items-center"},[_c('t-checkbox',{model:{value:(_vm.acceptedPrivacy),callback:function ($$v) {_vm.acceptedPrivacy=$$v},expression:"acceptedPrivacy"}}),_c('span',{staticClass:"ml-2 text-sm"},[_vm._v(_vm._s(_vm.$i18n.t("read_and_accepted")))]),_c('a',{staticClass:"ml-1 my-auto",staticStyle:{"color":"#555bcd"},attrs:{"href":"/privacypolicies_es.pdf","target":"_blank"}},[_c('strong',[_vm._v(_vm._s(_vm.$i18n.t("the_privacy_policy")))])])],1),_c('t-button',{staticClass:"mt-3 w-full",attrs:{"disabled":_vm.isLoading || !_vm.acceptedTerms || !_vm.acceptedPrivacy},on:{"click":_vm.register}},[_vm._v(" "+_vm._s(_vm.$i18n.t("signup"))+" "),(_vm.isLoading)?_c('t-loader',{attrs:{"extraClass":"ml-2 border-orange-primary"}}):_vm._e()],1)],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }