<template>
  <div class="h-screen mt-3 md:flex md:items-center">
    <div class="relative w-5/6 md:w-2/3 mx-auto">
      <div class="relative w-full rounded-3xl p-8 bg-white shadow-md">
        <h1
          class="text-2xl text-center md:text-left md:text-5xl text-primary-1000"
        >
          {{ $i18n.t("signup") }}
        </h1>
        <div class="flex flex-col justify-center">
          <div class="flex items-center mt-2">
            <p class="font-light mr-2">
              {{ $i18n.t("already_have_an_account") }}
            </p>
            <t-button variant="link" to="/login" :disabled="isLoading">{{
              $i18n.t("login")
            }}</t-button>
          </div>

          <div class="mt-8">
            <div class="flex flex-col md:flex-row">
              <t-input-group
                :feedback="
                  showValidation && errors.first('first_name')
                    ? errors.first('first_name')
                    : ''
                "
                :variant="
                  showValidation && errors.first('first_name') ? 'danger' : ''
                "
                class="md:w-1/2 md:mr-2 mb-2"
              >
                <t-input
                  v-validate="'required|min:3|max:40'"
                  data-vv-validate-on="blur"
                  classes="mb-4 bg-transparent"
                  v-model="user.first_name"
                  name="first_name"
                  :placeholder="$i18n.t('name')"
                  autocomplete="off"
                  :disabled="isLoading"
                  type="text"
                  :variant="
                    showValidation && errors.first('first_name') ? 'danger' : ''
                  "
                />
              </t-input-group>
              <t-input-group
                :feedback="
                  showValidation && errors.first('last_name')
                    ? errors.first('last_name')
                    : ''
                "
                :variant="
                  showValidation && errors.first('last_name') ? 'danger' : ''
                "
                class="md:w-1/2 md:mr-2 mb-2"
              >
                <t-input
                  v-validate="'required|min:3|max:40'"
                  data-vv-validate-on="blur"
                  classes="mb-4 bg-transparent"
                  v-model="user.last_name"
                  name="last_name"
                  :placeholder="$i18n.t('last_name')"
                  autocomplete="off"
                  :disabled="isLoading"
                  type="text"
                  :variant="
                    showValidation && errors.first('last_name') ? 'danger' : ''
                  "
                />
              </t-input-group>
            </div>

            <div class="flex mb-2 w-full">
              <t-input-group
                :feedback="
                  showValidation && errors.first('username')
                    ? errors.first('username')
                    : ''
                "
                :variant="
                  showValidation && errors.first('username') ? 'danger' : ''
                "
                :class="checkingUsername ? 'w-11/12' : 'w-full'"
              >
                <t-input
                  v-validate="'required|username'"
                  data-vv-validate-on="input"
                  classes="mb-4 bg-transparent"
                  v-model="user.username"
                  @blur="debouncedInput"
                  name="username"
                  :placeholder="$i18n.t('username')"
                  autocomplete="off"
                  :disabled="isLoading"
                  :variant="
                    showValidation && errors.first('username') ? 'danger' : ''
                  "
                />
              </t-input-group>
              <icon
                v-if="checkingUsername"
                class="text-xl mt-3 mx-auto"
                :class="validUsername ? 'text-green-600' : 'text-red-600'"
                :icon="validUsername ? 'check-circle' : 'times-circle'"
              />
            </div>

            <t-input-group
              :feedback="
                showValidation && errors.first('email')
                  ? errors.first('email')
                  : ''
              "
              :variant="showValidation && errors.first('email') ? 'danger' : ''"
              class="mb-2"
            >
              <t-input
                v-validate="'required|email'"
                data-vv-validate-on="blur"
                classes="mb-4 bg-transparent"
                v-model="user.email"
                name="email"
                :placeholder="$i18n.t('email')"
                autocomplete="off"
                :disabled="isLoading || (googleUser && googleUser.email.length > 0)"
                type="email"
                :variant="
                  showValidation && errors.first('email') ? 'danger' : ''
                "
              />
            </t-input-group>

            <t-input-group
              :feedback="
                showValidation && errors.first('lang')
                  ? errors.first('lang')
                  : ''
              "
              :variant="showValidation && errors.first('lang') ? 'danger' : ''"
              class="mb-2"
            >
              <t-rich-select
                v-validate="'required'"
                data-vv-validate-on="blur"
                v-model="user.lang"
                name="lang"
                :placeholder="$i18n.t('language')"
                :disabled="isLoading"
                noResultsText="No results"
                :variant="
                  showValidation && errors.first('lang') ? 'danger' : ''
                "
                :options="languages"
                :textAttribute="'text_' + $i18n.locale"
              />
            </t-input-group>

            <t-input-group
              :feedback="
                showValidation && errors.first('id_country')
                  ? errors.first('id_country')
                  : ''
              "
              :variant="
                showValidation && errors.first('id_country') ? 'danger' : ''
              "
              class="mb-2"
            >
              <t-rich-select
                v-model="user.id_country"
                name="id_country"
                :placeholder="$i18n.t('country') + ' ' + $i18n.t('optional')"
                :disabled="isLoading"
                noResultsText="No results"
                :variant="
                  showValidation && errors.first('id_country') ? 'danger' : ''
                "
                :options="countries"
                :textAttribute="$i18n.locale + '_name'"
              />
            </t-input-group>

            <t-input-group
              :feedback="
                showValidation && errors.first('password')
                  ? errors.first('password')
                  : ''
              "
              :variant="
                showValidation && errors.first('password') ? 'danger' : ''
              "
              class="mb-2 relative"
            >
              <t-input
                v-validate="'required'"
                data-vv-validate-on="blur"
                classes="mb-4 bg-transparent"
                v-model="user.password"
                name="password"
                :placeholder="$i18n.t('password')"
                autocomplete="off"
                :disabled="isLoading"
                :type="type"
                ref="password"
                :variant="
                  showValidation && errors.first('password') ? 'danger' : ''
                "
              />
              <span
                class="absolute top-20 right-3 cursor-pointer"
                @click="showPassword('typeInput')"
                ><icon :icon="typeInput === 'password' ? 'eye' : 'eye-slash'"
              /></span>
            </t-input-group>

            <t-input-group
              :feedback="
                showValidation && errors.first('passwordConfirm')
                  ? errors.first('passwordConfirm')
                  : ''
              "
              :variant="
                showValidation && errors.first('passwordConfirm')
                  ? 'danger'
                  : ''
              "
              class="mb-2 relative"
            >
              <t-input
                v-validate="'required|confirmed:password'"
                data-vv-validate-on="blur"
                classes="mb-4 bg-transparent"
                v-model="passwordConfirm"
                name="passwordConfirm"
                :placeholder="$i18n.t('confirm_password')"
                autocomplete="off"
                :disabled="isLoading"
                :type="typeConfirm"
                :variant="
                  showValidation && errors.first('passwordConfirm')
                    ? 'danger'
                    : ''
                "
              />
              <span
                class="absolute top-20 right-3 cursor-pointer"
                @click="showPassword('typeInputConfirm')"
                ><icon
                  :icon="typeInputConfirm === 'password' ? 'eye' : 'eye-slash'"
              /></span>
            </t-input-group>

            <div class="flex items-center">
              <t-checkbox v-model="acceptedTerms" />
              <span class="ml-2 text-sm">{{
                $i18n.t("read_and_accepted")
              }}</span>
              <a
                href="/termsandconditions_es.pdf"
                class="ml-1 my-auto"
                style="color: #555bcd"
                target="_blank"
                ><strong>{{ $i18n.t("the_terms_and_conditions") }}</strong></a
              >
            </div>

            <div class="flex items-center">
              <t-checkbox v-model="acceptedPrivacy" />
              <span class="ml-2 text-sm">{{
                $i18n.t("read_and_accepted")
              }}</span>
              <a
                href="/privacypolicies_es.pdf"
                class="ml-1 my-auto"
                style="color: #555bcd"
                target="_blank"
                ><strong>{{ $i18n.t("the_privacy_policy") }}</strong></a
              >
            </div>

            <t-button
              class="mt-3 w-full"
              @click="register"
              :disabled="isLoading || !acceptedTerms || !acceptedPrivacy"
            >
              {{ $i18n.t("signup") }}
              <t-loader
                v-if="isLoading"
                extraClass="ml-2 border-orange-primary"
              />
            </t-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import { debounce } from "lodash"
export default {
  data() {
    return {
      user: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        id_role: 2
      },
      passwordConfirm: "",
      showValidation: true,
      typeInput: "password",
      typeInputConfirm: "password",
      isLoading: false,
      languages: [
        { text_es: "Español", text_en: "Spanish", value: "es" },
        { text_es: "Inglés", text_en: "English", value: "en" }
      ],
      validUsername: false,
      checkingUsername: false,
      lastCheckedUsername: "",
      acceptedTerms: false,
      acceptedPrivacy: false
    }
  },
  computed: {
    googleUser() {
      return this.$store.getters["auth/getGoogleUser"]
    },
    type() {
      return this.typeInput
    },
    typeConfirm() {
      return this.typeInputConfirm
    },
    countries() {
      return this.$store.getters["country/getAllUnpaginated"].map(
        ({ id, es_name, en_name }) => {
          return {
            es_name: es_name,
            en_name: en_name,
            value: id
          }
        }
      )
    },
    usernameCheck() {
      return this.$store.getters["getUsernameCheck"]
    }
  },
  methods: {
    debouncedInput() {},

    checkUsername() {
      if (this.user.username !== "") {
        const blank_space = String(this.user.username).indexOf(" ")
        if (blank_space >= 0)
          return this.$snotify.error(
            this.$i18n.locale === "en"
              ? "Username cannot have empty spaces"
              : "El nombre de usuario no puede tener espacios en blanco",
            "Error",
            {
              timeout: 5000,
              showProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              titleMaxLength: 40,
              bodyMaxLength: 100,
              position: "rightBottom"
            }
          )
      }
      if (!/^[a-z._0-9]+$/i.test(this.user.username)) {
        this.checkingUsername = false
        return
      } else {
        this.checkingUsername = true
      }

      if (this.user.username !== this.lastCheckedUsername) {
        if (this.user.username !== "")
          this.$socket.emit("check_username", {
            username: this.user.username
          })
        setTimeout(() => {
          if (this.user.username !== "") {
            this.checkingUsername = true
          } else {
            this.checkingUsername = false
          }
        }, 250)
      }

      this.lastCheckedUsername = this.user.username
    },
    showPassword(ref) {
      if (this[ref] === "password") {
        this[ref] = "text"
      } else {
        this[ref] = "password"
      }
    },
    register() {
      this.$validator.validateAll().then(async (isValid) => {
        this.showValidation = true
        if (isValid) {
          this.isLoading = true
          this.$store
            .dispatch("auth/"+(this.googleUser ? 'googleRegister' : 'register'), this.googleUser ? { payload: this.user, aclChangeRole: this.$acl.change, i18n: this.$i18n } : this.user)
            .then((response) => {
              this.isLoading = false
              notify().then(({ notification }) => {
                notification(
                  "register",
                  response.code,
                  this.$snotify,
                  this.$i18n.locale
                )
              })
            })
            .catch((error) => {
              this.isLoading = false
              notify().then(({ notification }) => {
                notification(
                  "error",
                  error.code,
                  this.$snotify,
                  this.$i18n.locale
                )
              })
            })
        }
      })
    },
    getCountries() {
      this.$store
        .dispatch("global/getItems", {
          route: "/country/all",
          module: "country",
          noPaginate: true,
          isMaster: true,
          params: {
            active: 1,
            orderKey: this.$i18n.locale + "_name",
            orderValue: "asc"
          }
        })
        .then(() => {})
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
  },
  created() {
    if (this.googleUser) {
      this.user = {
        first_name: this.googleUser.given_name,
        last_name: this.googleUser.family_name,
        email: this.googleUser.email,
        password: '',
        id_role: 2
      }
    }

    this.getCountries()
    this.debouncedInput = debounce(this.checkUsername, 200)
  },
  watch: {
    usernameCheck: {
      handler() {
        if (!this.usernameCheck.username_valid) {
          this.$snotify.error(
            this.$i18n.locale === "en"
              ? "Only lowercase letters are allowed"
              : "Solo se permiten letras minúsculas",
            "Error",
            {
              timeout: 5000,
              showProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              titleMaxLength: 40,
              bodyMaxLength: 100,
              position: "rightBottom"
            }
          )
          this.validUsername = false
        } else if (!this.usernameCheck.username_available) {
          this.$snotify.error(
            this.$i18n.locale === "en"
              ? "This username is already in use"
              : "Este nombre de usuario ya está en uso",
            "Error",
            {
              timeout: 5000,
              showProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              titleMaxLength: 40,
              bodyMaxLength: 100,
              position: "rightBottom"
            }
          )
          this.validUsername = false
        } else {
          this.validUsername = true
        }
      },
      deep: true
    }
  }
}
</script>
